import gql from 'graphql-tag';

import { ContentMenuFragment } from './ContentMenuFragment.fragment';

export const StarredContentMenuQuery = gql`
	query StarredContentMenuQuery($limit: Int = 15, $start: Int!) {
		favoriteContent(limit: $limit, start: $start) {
			nodes {
				...ContentMenuFragment
			}
			pageInfo {
				hasNextPage
			}
			edges {
				cursor
			}
		}
	}
	${ContentMenuFragment}
`;
