import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Box, Stack, Text } from '@atlaskit/primitives';
import Image from '@atlaskit/image';
import Skeleton from '@atlaskit/skeleton';
import { token } from '@atlaskit/tokens';

import errorWarning from './assets/errorWarning.svg';
import errorSearchLight from './assets/errorSearchLight.svg';
import errorSearchDark from './assets/errorSearchDark.svg';

type ContentMenuErrorStateProps = {
	errorTitle: string;
};

type ContentMenuNoResultsStateProps = {
	message?: string;
	advancedSearchLink?: React.ReactElement;
};

type ContentMenuEmptyStateProps = {
	image: string;
	title: string;
	message: string;
};

type ImageTextStackProps = {
	image: string;
	message: string;
	title?: string;
	darkImage?: string;
	advancedSearchLink?: React.ReactElement;
};

const ImageWithText = ({
	image,
	message,
	title,
	darkImage,
	advancedSearchLink,
}: ImageTextStackProps) => {
	return (
		<Box paddingBlock="space.800">
			<Stack alignInline="center" space="space.100" grow="fill">
				<Image src={image} srcDark={darkImage || ''} alt="" width="160px" height="160px" />
				<Stack alignInline="center" space="space.075">
					{title && (
						<Text weight="bold" color="color.text.subtle">
							{title}
						</Text>
					)}
					<Text size="small" color="color.text.subtle" align="center" css={{ textWrap: 'balance' }}>
						{message}
					</Text>
					<Text size="small">{advancedSearchLink}</Text>
				</Stack>
			</Stack>
		</Box>
	);
};

export const ContentMenuErrorState = ({ errorTitle }: ContentMenuErrorStateProps) => {
	const intl = useIntl();
	return (
		<ImageWithText
			image={errorWarning}
			title={errorTitle}
			message={intl.formatMessage(i18n.errorMessageSubtitle)}
		/>
	);
};

export const ContentMenuNoResultsState = ({
	message,
	advancedSearchLink,
}: ContentMenuNoResultsStateProps) => {
	const intl = useIntl();
	return (
		<ImageWithText
			image={errorSearchLight}
			message={message || intl.formatMessage(i18n.noResultsMessageSubtitle)}
			darkImage={errorSearchDark}
			advancedSearchLink={advancedSearchLink}
		/>
	);
};

export const ContentMenuEmptyState = ({ image, title, message }: ContentMenuEmptyStateProps) => {
	return <ImageWithText image={image} title={title} message={message} />;
};

const skeletonProps = {
	height: '9px',
	borderRadius: '3px',
	isShimmering: true,
	color: token('color.skeleton'),
	ShimmeringEndColor: token('color.skeleton.subtle'),
};

export const ContentMenuLoadingState = () => {
	return (
		<Box paddingBlock="space.300">
			<Stack
				space="space.300"
				alignBlock="center"
				alignInline="center"
				grow="fill"
				testId="content-menu-loading"
			>
				{[...Array(10)].map((_, index) => (
					<Skeleton key={index} width="250px" {...skeletonProps} />
				))}
			</Stack>
		</Box>
	);
};

export const ContentMenuLoadMoreState = () => {
	return (
		<Box padding="space.300">
			<Stack space="space.300" grow="fill" testId="content-menu-load-more">
				<Skeleton width="180px" {...skeletonProps} />
				<Skeleton width="140px" {...skeletonProps} />
				<Skeleton width="180px" {...skeletonProps} />
				<Skeleton width="140px" {...skeletonProps} />
			</Stack>
		</Box>
	);
};

const i18n = defineMessages({
	errorMessageSubtitle: {
		id: 'side-navigation.content-menu.error-message.subtitle',
		defaultMessage: 'Refresh and try again.',
		description: 'Subtitle for the error message in the content menu',
	},
	noResultsMessageSubtitle: {
		id: 'side-navigation.content-menu.no-results-message.subtitle',
		defaultMessage: 'We couldn’t find what you’re looking for.',
		description: 'Subtitle for the no results message in the content menu',
	},
});
